#TermsOfUse {
  width: 100%;
  padding: 80px 0;
}
#TermsOfUse .container {
  align-items: center;
  justify-content: center;
}
#TermsOfUse .container > div {
  max-width: 60%;
  align-items: flex-start;
}
#TermsOfUse .container > div p {
  text-align: left;
}
#TermsOfUse .container > div p.bold {
  font-weight: bold;
  color: #bea45a;
  margin-bottom: 0px;
}

@media all and (max-width: 768px) {
  #TermsOfUse .container > div {
    max-width: 100%;
    align-items: flex-start;
  }
}
@media all and (max-width: 520px) {
}
