.App {
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #1a1a1a;
}
.App.is-sticky {
  padding-top: 80px;
}
* {
  box-sizing: border-box;
}
body {
  background-color: #1a1a1a;
}
h1 {
  font-size: 40px;
  margin-bottom: 20px;
  margin-top: 0;
  color: #fff;
}
h2 {
  margin-top: 10px;
  margin-bottom: 8px;
  font-size: 30px;
  color: #fff;
}
h3 {
  color: #fff;
  line-height: 28px;
}
h4 {
  color: #fff;
}
p {
  line-height: 24px;
  margin-bottom: 10px;
  color: #fff;
}
a {
  text-decoration: none;
  color: #bea45a;
  font-weight: bold;
}
button {
  border: 1px solid #bea45a;
  border-radius: 12px;
  background: none;
  padding: 15px 25px;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}
div,
header,
menu,
footer {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* width: 100%; */
}

header {
  background-color: #141414;
  height: 80px;
  width: 100%;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 1366px;
  padding: 0 30px;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: red; */
}

menu {
  flex-direction: row;
  justify-content: flex-end;
}

menu a {
  margin-left: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
}
menu a.login {
  background-color: #bea45a;
  padding: 8px 20px;
  border-radius: 8px;
}
menu a.active {
  color: #bea45a;
}

#header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.logo {
  width: 80px;
}

@media all and (max-width: 1024px) {
  h1 {
    font-size: 34px;
    margin-bottom: 20px;
    color: #fff;
  }
  h2 {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 8px;
    color: #fff;
  }
  p {
    line-height: 24px;
    margin-bottom: 10px;
    color: #fff;
  }
}
@media all and (max-width: 768px) {
}
@media all and (max-width: 520px) {
  .logo {
    width: 60px;
  }
  .container {
    padding: 0 20px;
  }
  menu {
    padding: 0;
  }
  menu a {
    margin-left: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}
