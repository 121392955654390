.features {
  background-color: #242424;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-left: 5px solid #bea45a;
  padding: 80px 50px;
}
.features div:first-child {
  justify-content: center;
}
.features h2 {
  margin-top: 25px;
}
.features h2,
.features p {
  text-align: center;
}
.features .container > div {
  max-width: 50%;
  padding: 0 50px;
}
.features .text-wrapper {
  margin-top: 100px;
  align-self: center;
  background: #1a1a1a;
  padding: 50px;
}

.features .cols {
  margin-top: 80px;
}

.features .cols > div {
  max-width: 33%;
}
.features span {
  color: #bea45a;
  font-style: italic;
}
@media all and (max-width: 1024px) {
  .features .container {
    padding: 0;
  }
  .features .cols > div {
    padding: 0 20px;
  }
}
@media all and (max-width: 768px) {
  .features {
    padding: 60px 20px;
  }
}
@media all and (max-width: 520px) {
  .features {
    padding: 60px 20px 20px;
  }
  .features .container {
    max-width: 100%;
    padding: 0 20px;
    flex-direction: column !important;
  }
  .features .container > div {
    max-width: 100%;
    padding: 0 20px;
    margin-bottom: 60px;
    flex-direction: column !important;
  }
  .features .cols {
    margin-top: 0px;
  }
}
