.footer {
  background-color: #bea45a;
  width: 100%;
  padding: 40px 60px 80px;
}
.footer .container {
  width: 100%;
  padding: 0;
}
.footer .cols {
  margin-top: 0px;
}
.footer .cols > div {
  max-width: 20%;
}
.footer div {
  align-items: flex-start;
}
.footer h4 {
  margin-top: 8px;
  margin-bottom: 6px;
  color: #242424;
}
.footer p {
  margin-top: 0px;
  margin-bottom: 8px;
  color: #242424;
}
.footer a {
  color: #242424;
  font-weight: normal;
}
.footer a:hover {
  text-decoration: underline;
}
.footer a.mail-link {
  font-weight: bold;
}
.footer img {
  width: 130px;
}
@media all and (max-width: 768px) {
  .footer {
    background-color: #bea45a;
    width: 100%;
    padding: 40px 30px 80px;
  }
  .footer .cols {
    flex-wrap: wrap;
  }
  .footer .cols > div:first-child {
    max-width: 100%;
    width: 100%;
    margin-bottom: 30px;
  }
}
@media all and (max-width: 768px) {
  .footer .cols {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }
  .footer .cols > div {
    max-width: 100%;
    width: 100%;
    margin-bottom: 30px;
  }
}
