.mission {
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
}
.mission-img {
  width: 100%;
  position: relative;
  border-right: 5px solid #bea45a;
  border-bottom: 5px solid #bea45a;
}
.mission h2 {
  margin-top: 0;
}
.mission a {
  margin-top: 15px;
}
.abstract-wrp {
  overflow: hidden;
  /* position: absolute; */
  width: 50%;
  max-height: 800px;
  height: 100%;
  z-index: 1;
}
.abstract-wrp .inner {
  overflow: hidden;
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* transform: skewX(-45.98deg);
  transform-origin: 100% 0; */
}
.abstract {
  /* transform: skewX(45.98deg); */
  width: 100%;
  /* transform-origin: inherit; */
}
.mission .stripes {
  position: absolute;
  width: 100%;
  /* min-height: 700px; */
  height: 100%;
  z-index: 1;
  background: repeating-linear-gradient(
    to right,
    transparent,
    transparent 11px,
    #777 1px,
    #777 12px
  );
}
.mission .text-wrapper {
  /* margin-top: 100px; */
  align-self: center;
  background: #1a1a1a;
  padding: 50px;
}
@media all and (max-width: 1024px) {
  .mission .text-wrapper {
    padding: 30px;
    max-width: 55%;
    align-items: flex-start;
  }
}
@media all and (max-width: 768px) {
}
@media all and (max-width: 520px) {
  .mission {
    flex-direction: column;
  }
  .mission-img {
    width: 100%;
    border-bottom: none;
  }
  .mission .text-wrapper {
    padding: 50px 30px 60px;
    max-width: 100%;
    align-items: flex-start;
    border-right: 5px solid #bea45a;
    border-bottom: 5px solid #bea45a;
  }
}
