.hero {
  width: 100%;
  /* height: calc(100vh - 185px); */
  border-bottom: 5px solid #bea45a;
}

.hero .container {
  position: relative;
  padding-right: 0;
}

.hero .text-wrapper {
  align-items: flex-start;
  max-width: 50%;
}

.hero-img-wrapper {
  /* box-shadow: 0 0 8px 8px white inset; */
  width: 100%;
  max-width: 40%;
}

.hero-img {
  width: 100%;
  height: 100%;
}

.btn-wrp {
  align-items: flex-start;
}
.btn-wrp h3 {
  margin-bottom: 5px;
  color: #bea45a;
}
.btn-wrp h4 {
  margin-bottom: 5px;
  font-weight: normal;
  color: #bea45a;
  font-weight: bold;
}

.btn-wrp a {
  border: 1px solid #bea45a;
  background-color: #1a1a1a;
  padding: 15px 25px;
  color: #fff;
  border-radius: 8px;
  margin-top: 3px;
  transition: background 0.3s ease;
}

.btn-wrp a:hover {
  background-color: #bea45a;
  transition: background 0.3s ease;
}
@media all and (max-width: 1024px) {
  .hero h3 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .text-wrapper {
    padding: 30px 0;
  }
  .hero-img-wrapper {
    /* box-shadow: 0 0 8px 8px white inset; */
    max-width: 45%;
  }
}
@media all and (max-width: 768px) {
}
@media all and (max-width: 520px) {
  .hero .container {
    position: relative;
    padding-right: 20px;
    overflow: hidden;
  }
  .hero .text-wrapper {
    z-index: 1;
    max-width: 100%;
  }

  .hero-img-wrapper {
    opacity: 0.3;
    z-index: 0;
    position: absolute;
    right: 0;
    max-width: unset;
    max-height: 100%;
  }
}
