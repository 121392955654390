#Pricing {
  width: 100%;
}
#Pricing > .container {
  justify-content: center;
}
#Pricing .container .hero {
  max-width: 625px;
  width: 100%;
  border-bottom: none;
  padding: 80px 0;
  text-align: center;
  font-size: 22px;
  line-height: 34px;
  height: calc(100vh - 280px);
  justify-content: center;
}
#Pricing .container .hero p {
  font-size: 22px;
  line-height: 30px;
}
#Pricing .squares {
  width: 93%;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1300px;
  margin-bottom: 50px;
}
#Pricing .squares > div {
  width: 50%;
  min-height: 360px;
  border: 1px solid #777;
  justify-content: center;
}
#Pricing .squares img {
  width: 90%;
}
#Pricing .inner {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 93%;
  margin-bottom: 60px;
  max-width: 1300px;
}
#Pricing .inner .content {
  width: 100%;
}
#Pricing .stripes {
  top: 0;
  position: absolute;
  width: 100%;
  /* min-height: 700px; */
  height: 100%;
  z-index: 1;
  background: repeating-linear-gradient(
    to right,
    transparent,
    transparent 11px,
    #777 1px,
    #777 12px
  );
}
#Pricing h2 {
  font-size: 24px;
  /* border: 1px solid #bea45a;
  background-color: #1a1a1a;
  padding: 15px 25px;
  z-index: 1; */
  /* align-self: flex-start; */
  /* margin-left: 5%; */
  margin-bottom: 30px;
  margin-top: 0;
}
#Pricing h2 span {
  font-size: 16px;
  font-weight: normal;
}
#Pricing h3 {
  font-size: 30px;
  margin-bottom: 0;
}
.pricing {
  background-color: #1a1a1a;
  /* flex-direction: row; */
  position: relative;
  z-index: 1;
  max-width: 90%;
  padding: 20px 25px;
  border: 1px solid #bea45a;
  justify-content: space-between;
  width: 100%;
}
.pricing > div {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.pricing .head p {
  padding-top: 0;
  font-weight: bold;
}
.pricing .body {
  flex-direction: column;
}
.pricing .body p {
  color: #bea45a;
}
.pricing .body .row {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgb(190, 164, 90, 0.3);
}
.pricing .body .row p:first-child,
.pricing .head p:first-child,
.pricing .tableFooter p:first-child {
  width: 34%;
}
.pricing .body .row p:nth-child(2),
.pricing .head p:nth-child(2),
.pricing .tableFooter p:nth-child(2) {
  width: 22%;
  border-left: 1px solid rgb(190, 164, 90, 0.3);
  text-align: center;
}
.pricing .body .row p:nth-child(3),
.pricing .head p:nth-child(3),
.pricing .tableFooter p:nth-child(3) {
  width: 22%;
  border-left: 1px solid rgb(190, 164, 90, 0.3);
  text-align: center;
}
.pricing .body .row p:last-child,
.pricing .head p:last-child,
.pricing .tableFooter p:last-child {
  width: 22%;
  border-left: 1px solid rgb(190, 164, 90, 0.3);
  text-align: center;
}
.pricing p {
  margin: 0;
  padding: 12px 0 12px 0;
}
.pricing .tableFooter p {
  border-bottom: none;
  font-weight: bold;
}
.pricing .tableFooter p {
  padding-bottom: 0;
}

@media all and (max-width: 1024px) {
  #Pricing .inner {
    width: 100%;
  }
  #Pricing .squares {
    width: 93%;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  #Pricing .squares > div {
    width: 100%;
    min-height: 360px;
    border: 1px solid #777;
    justify-content: center;
  }
  #Pricing .squares img {
    width: 60%;
  }
  .mobile-pricing {
    width: 94%;
    z-index: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .mobile-pricing .item {
    background-color: #1a1a1a;
    border: 1px solid #bea45a;
    padding: 20px;
    width: 100%;
    margin-bottom: 20px;
  }
  .mobile-pricing .item .title {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .mobile-pricing .item .title h2 {
    margin-bottom: 0 !important;
  }
  .mobile-pricing .item .title p {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    color: #bea45a;
    font-weight: bold;
    font-size: 20px;
  }
  .mobile-pricing .item .list {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .mobile-pricing .item .list p {
    width: 50%;
  }
  .mobile-pricing .item .list p svg {
    margin-right: 5px;
  }
}
@media all and (max-width: 520px) {
  #Pricing .squares img {
    width: 90%;
  }
  #Pricing .squares > div {
    min-height: 220px;
  }
  .mobile-pricing .item h2 {
    margin-bottom: 5px !important;
  }
  .mobile-pricing .item .list {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .mobile-pricing .item .list p {
    width: 100%;
  }
}
