#OurMission {
  width: 100%;
}
#OurMission > .container {
  justify-content: center;
}
#OurMission .container .hero {
  max-width: 625px;
  width: 100%;
  border-bottom: none;
  padding: 80px 0;
  text-align: center;
  font-size: 22px;
  line-height: 34px;
  height: calc(100vh - 280px);
  justify-content: center;
}
#OurMission .container .hero p {
  font-size: 22px;
  line-height: 30px;
}
#OurMission .container .hero p:last-child {
  color: #bea45a;
  font-weight: 600;
}
#OurMission .inner {
  position: relative;
  padding-top: 200px;
  padding-bottom: 100px;
}
#OurMission .stripes {
  top: 0;
  position: absolute;
  width: 100%;
  /* min-height: 700px; */
  height: 100%;
  z-index: 1;
  background: repeating-linear-gradient(
    to right,
    transparent,
    transparent 11px,
    #777 1px,
    #777 12px
  );
}
#OurMission h2 {
  font-size: 18px;
  border: 1px solid #bea45a;
  background-color: #1a1a1a;
  padding: 15px 25px;
  z-index: 1;
  align-self: flex-start;
  margin-left: 5%;
  margin-bottom: 10px;
}
#OurMission h3 {
  font-size: 30px;
  margin-bottom: 0;
}
.purposes {
  background-color: #1a1a1a;
  flex-direction: row;
  position: relative;
  z-index: 1;
  max-width: 90%;
  padding: 80px 60px;
  border: 1px solid #bea45a;
  justify-content: space-between;
}
.purposes div {
  max-width: 28%;
  text-align: center;
}
@media all and (max-width: 768px) {
  .purposes {
    padding: 50px 30px;
  }
}
@media all and (max-width: 520px) {
  #OurMission .container .hero p {
    font-size: 18px !important;
    line-height: 26px !important;
  }
  #OurMission h3 {
    font-size: 26px;
    margin-top: 10px;
  }
  .purposes {
    flex-direction: column;
    padding: 40px 20px 20px;
  }
  .purposes div {
    max-width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }
}
